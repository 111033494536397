/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
const ReactDOM = require("react-dom");

const googleAnalyticsLoad = () => {
  // Create the <script> tag for the gtag.js library
  const scriptTag = document.createElement("script");
  scriptTag.async = true;
  scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTM_ID}`;
  document.head.appendChild(scriptTag);

  // Inline script content
  const inlineScript = document.createElement("script");
  const scriptContent = document.createTextNode(`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${process.env.GATSBY_GTM_ID}');
  `);
  inlineScript.appendChild(scriptContent);
  document.head.appendChild(inlineScript);
};

// const googleAnalyticsAttachEvents = () => {
//   const elements_emoji = document.querySelectorAll(
//     "a.emojibar-single-emoji-anchor"
//   );
//   elements_emoji.forEach((el, index) => {
//     el.addEventListener("click", function (e) {
//       e.preventDefault(); // Prevent the default anchor action
//       console.log(`hello from el_emoji_${index}`);
//       if (gtag)
//         gtag("event", "click_emoji", {
//           element: `emoji_${index}`,
//           component: "EmojiBar",
//         });
//     });
//   });
// };

// exports.replaceHydrateFunction = () => {
//   return (element, container) => {
//     const root = ReactDOM.createRoot(container);
//     root.render(element);
//     googleAnalyticsLoad();
//     googleAnalyticsAttachEvents();
//   };
// };

exports.onRouteUpdate = () => {
  // googleAnalyticsLoad();
};
